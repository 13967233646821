import React from 'react'
import Navbar from '../inc/Navbar'
import Sofa from './Sofa'
import {Link} from "react-router-dom";


function Sofapage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
       
        <div className='eyeglassesberhampore'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" href="#">Home</Link></li>
                              
                                <li className="breadcrumb-item active" aria-current="page">Living Room</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

        <Sofa/>
    </div>
  )
}

export default Sofapage