import React from 'react'
import Daining from "../images/Daining-table.jpg";
import Bedroom from "../images/bedroom.jpg";
import Office from "../images/office-furnture.jpg";
import Livingroom from "../images/lving.jpg";


function Product() {
    return (
        <div>
            <br />
            <div className="album py-0">
                <div className="container">
                    <h4 style={{ color: "darkblue" }}>Our Latest Catagory</h4>

                </div>

            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Dining'>
                                        <div className="card">
                                            <img src={Daining} className="card-img-top" alt="Daining" />
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1">Daining Table</h6>


                                                <div className="btn-grad15" type="submit"><small>View Details</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='bedroom'>
                                        <div className="card">
                                            <img src={Bedroom} className="card-img-top" alt="Bedroom" />
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1">Bedroom</h6>

                                                <a href="#" className="btn-grad15"><small>View Details</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='office-furniture'>
                                        <div className="card">
                                            <img src={Office} className="card-img-top" alt="Office-furniture" />
                                            <div className="card-body">
                                                <h6 className="card-title mb-1"><small>Office Furniture</small></h6>

                                                <a href="#" className="btn-grad15"><small>View Details</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Living'>
                                        <div className="card">
                                            <img src={Livingroom} className="card-img-top" alt="Livingroom" />
                                            <div className="card-body">
                                                <h6 className="card-title mb-1" >Sofa</h6>

                                                <a href="#" className="btn-grad15"><small>View Details</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product