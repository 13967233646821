import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Product from '../inc/Product'
import "./Home.css";
import Latestp from '../inc/Latestp';
import speechs1 from "../images/sb-steel.mpeg";
import ScrollToTop from "react-scroll-to-top";
import ReactPlayer from "react-player";
import Sofa from './Sofa';

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <ScrollToTop smooth top="100" color="maroon" />
      <div className='album py-0'>
        <div className='container'>
          <h5>Subho beding settl furniture</h5>
        </div>
      </div>
      <div className="furniture">
        <div className="album py-1">

          <div className="container">

            <div className="row ">

              <div className="col-md-12" >

                <center>

                  <marquee>
                    <h5 style={{ color: "white" }} className='mb-1 mt-2'> স্বাগতম  শুভ বেডিং ও স্টিল ফার্নিচার । বেলডাঙ্গা </h5></marquee>


                </center>

              </div>

              <div className="d-flex justify-content-between align-items-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider />

      <Product />

      <br />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="videreactjs">
              <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=-iW_a0qH7QA" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="album py-2">

        <div className="container">
          <h2 style={{ color: "maroon" }}>Latest Furniture</h2>
        </div>
      </div>

      <Latestp />
      <br />
      <br/>
      <div className="album py-0">

        <div className="container">
          <h2 style={{ color: "maroon" }}>Sofa Page</h2>
        </div>
      </div>
      <Sofa />
      <br/>
    
      <div className="album py-0">

        <div className="container">

          <div className="row">

            <iframe src="https://www.google.com/maps/embed?pb=!4v1717488007135!6m8!1m7!1sUHmEOSwzLJZ1auLWhPTxVQ!2m2!1d23.93264251227808!2d88.25297413891971!3f225.40810800569977!4f1.363079055903853!5f1.8111771458912806" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
        </div>
      </div>
      <br />

      <br />
      <div className='album py-1'>
        <div className='container'>
          <center>
            <audio controls>
              <source src={speechs1} type="audio/mp3" /></audio>
          </center>
        </div>
      </div>

      <br />
    </div>
  )
}

export default Home