import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'



import ReactWhatsapp from "react-whatsapp";



function Latestp() {

    const [isExpanded, setIsExpanded] = useState(false);

const toggleExpanded = () => {
  setIsExpanded(!isExpanded);
};


    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "LATEST-PRODUCT");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
         
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-4 border-White box shadow">
                                        <img className="card-img-top" src={centralsericultural.img} alt="PRODUCT-FURNITURE-BELDANGA"/>
                                        <div className="card-body">
                                      
                                         

<h5 style={{color:"Darkblue"}}>{centralsericultural.title}</h5>
<p className="card-text mb-2"> <small>{centralsericultural.des}</small></p>

                                           
<div className="d-flex justify-content-between align-items-center">
                              
                              <a href='tel: 8670748017' className="btn btn-outline-dark btn-sm mb-0">Call Book</a>
                              <ReactWhatsapp number="+91 8670748017" className="btn btn-success btn-sm mb-0" message="I want to buy this Bed Room, contact me"> Whatsapp Book</ReactWhatsapp>
                          
                           </div>

                                            
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Latestp;



