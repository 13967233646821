
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCCRbeZ5zFl9oYI4C1x_7VDVGIT6h8nX4w",
  authDomain: "subhobeding-furniture.firebaseapp.com",
  projectId: "subhobeding-furniture",
  storageBucket: "subhobeding-furniture.appspot.com",
  messagingSenderId: "45178423023",
  appId: "1:45178423023:web:763ed32a823f6a9f55fd1a",
  measurementId: "G-55LFRDDDKF"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;