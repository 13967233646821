import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'


import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import Navbar from '../inc/Navbar';




function OfficeF () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(50);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [bedroom, setBedroom] = useState([])
      const navigate = useNavigate()
  
      const bedroomCollectionRef = collection(db, "OFFICE-FURNITURE");
      useEffect(() => {
  
          const getBedroom = async () => {
              const data = await getDocs(bedroomCollectionRef);
              setBedroom(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getBedroom()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="maroon"/>

<br/>


     
<div className='eyeglassesberhampore'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" href="#">Home</Link></li>
                              
                                <li className="breadcrumb-item active" aria-current="page">Office Furniture</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

<div className="album py-3">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {bedroom.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
       
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((subhabedingsteelfurniture) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 ">
                        <img  className="card-img-top" src={subhabedingsteelfurniture.img}  alt="bedroom"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{subhabedingsteelfurniture.title}</b></h6>

                                <p className="card-text mb-3">{subhabedingsteelfurniture.des}</p>

                                <div className="d-flex justify-content-between align-items-center">
                              
                              <a href='tel: 8670748017' className="btn btn-outline-dark btn-sm mb-0">Call Book</a>
                              <ReactWhatsapp number="+91 8670748017" className="btn btn-success btn-sm mb-0" message="I want to buy this Bed Room, contact me"> Whatsapp Book</ReactWhatsapp>
                          
                           </div>
                
                               
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                 

                            </div>
                            </div>

</>
        
        );
    }
    
    export default OfficeF;



