import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Bedroom from './components/pages/Bedroom';
import Contact from './components/pages/Contact';
import Dining from './components/pages/Dining';
import Sofapage from './components/pages/Sofapage';
import OfficeF from './components/pages/OfficeF';
import Wardrobepage from './components/pages/Wardrobepage';
function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/bedroom" element={<Bedroom/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      
      <Route axact path="/Dining" element={<Dining/>}/>
      <Route axact path="/sofa" element={<Sofapage/>}/>
      <Route axact path="/office-furniture" element={<OfficeF/>}/>
      <Route axact path="/Wardrobe-page" element={<Wardrobepage/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
